import React from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import SEO from "../components/seo"
import Logo from "../components/logo.svg"
import { mergeClasses } from "@material-ui/styles"
import { makeStyles } from "@material-ui/core/styles"
import CompoundInterest from "../components/compound-interest"

const styles = makeStyles(theme => ({
  root: {
    justifyContent: "center",
  },
  logo: {
    width: "300px",
  },
  titleText: {
    textAlign: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    height: "240px",
  },
  startButton: {
    marginBottom: 10,
  },
}))

export default function Home(props) {
  const classes = styles()
  const investments = [
    {
      name: "Cash - 0.90%",
      rate: 0.009,
    },
    {
      name: "Short Term Investments - 1.00%",
      rate: 0.01,
    },
    {
      name: "Bonds - 5.00%",
      rate: 0.05,
    },
    {
      name: "Stocks - 10.00%",
      rate: 0.1,
    },
  ]

  return (
    <Layout landing={true}>
      <SEO title="Financial Literacy Education Resources" />
      <Grid container justify="center">
        <h1 className={classes.titleText}>
          Teach Financial Literacy With Our Free Resources
        </h1>
      </Grid>
      <div className={classes.center}>
        <img
          src={Logo}
          className={classes.logo}
          alt="Logo of a map with a dollar sign"
        />
      </div>
      <p>
        Explore Personal Finance is a website dedicated to teaching financial
        skills to help people get out of debt and growth their wealth. Our site
        is:
      </p>
      <ul>
        <li>
          <strong>
            Organized like a course, not an assortment of blog posts
          </strong>
        </li>
        <li>
          <strong>Free of confusing ads that detract from learning</strong>
        </li>
        <li>
          <strong>Interactive</strong>
        </li>
      </ul>
      <p>
        Our articles{" "}
        <a href="/assets/summary">explain different types of investments</a>,{" "}
        <a href="/liabilities/summary">
          teach about how expensive various types of debts can be
        </a>
        ,{" "}
        <a href="/assets/stocks">explain how to invest in the stock market </a>
        and why index funds are the safest way to do so,{" "}
        <a href="/intro/progressive-income-tax/">
          help navigate the tax system
        </a>
        , and much more. We walk the student through a set of basic building
        blocks with interactive exercises and visualizations, and guide with an
        opinionated approach towards proven, lower risk strategies to build
        wealth.
      </p>
      <Grid container justify="center">
        <Button
          className={classes.startButton}
          variant="contained"
          color="primary"
          size="large"
          href="/intro/definitions/"
        >
          Start The Course Here!
        </Button>
      </Grid>
      <hr />
      <h1>Interactive Visualizations</h1>
      <p>
        Charts like this one can help your students see how their money grows.
      </p>
      <Grid container justify="center">
        <CompoundInterest investments={investments} defaultYears={15} />
      </Grid>
      <hr />
      <h1>Need Something Else? Contact Us</h1>
      <p>
        <strong>
          We're trying to get this website off the ground. If you have another
          idea of what you need, please{" "}
          <a href="mailto:contactus@explorepersonalfinance.com">
            email us here!
          </a>
        </strong>
      </p>
    </Layout>
  )
}
